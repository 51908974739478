import httpClient from './http-client';

const syncSubscriptionPlans = (): Promise<any> => {
    return httpClient.invoke('nora', 'syncSubscriptionPlans');
};

const syncSubscribers = (): Promise<any> => {
    return httpClient.invoke('nora', 'syncSubscribers');
};

export default {
    syncSubscriptionPlans,
    syncSubscribers
}