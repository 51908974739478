const data = [
    {
        id: 'isp',
        name: 'ISP'
    },
    {
        id: 'service',
        name: 'Serviço'
    }
];

export default [...data];