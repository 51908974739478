import httpClient from './http-client';

const list = (filter: any): Promise<any> => {
    const params = {
        query: filter
    };

    return httpClient.invoke('subscriber', 'list', params);
};


const exportExcel = (providerId): Promise<any> => {
    if (providerId) {
        const params = {
            query: {
                providerId
            }
        };

        return httpClient.invoke('subscriber', 'exportProviderExcel', params);
    } else {
        return httpClient.invoke('subscriber', 'exportExcel');
    }
};

const exportCsv = (providerId): Promise<any> => {
    if (providerId) {
        const params = {
            query: {
                providerId
            }
        };

        return httpClient.invoke('subscriber', 'exportProviderCsv', params);
    } else {
        return httpClient.invoke('subscriber', 'exportCsv');
    }
};

const exportJson = (): Promise<any> => {
    return httpClient.invoke('subscriber', 'exportJson');
};

const get = (providerId, subscriberId: string): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriberId
        }
    };

    return httpClient.invoke('subscriber', 'get', params)
};

const add = (providerId, data: any): Promise<any> => {
    const params = {
        query: {
            providerId
        },
        data: data
    };

    return httpClient.invoke('subscriber', 'add', params)
};

const update = (providerId, subscriberId: string, data: any): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriberId
        },
        data: data
    };

    return httpClient.invoke('subscriber', 'update', params)
};

const changeStatus = (providerId, subscriberId: string, data: any): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriberId
        },
        data: data
    };

    return httpClient.invoke('subscriber', 'changeStatus', params)
};

const remove = (providerId, subscriberId: string): Promise<any> => {
    const params = {
        query: {
            providerId,
            subscriberId
        }
    };

    return httpClient.invoke('subscriber', 'remove', params)
};

export default {
    list,
    exportExcel,
    exportCsv,
    exportJson,
    get,
    add,
    update,
    changeStatus,
    remove
}