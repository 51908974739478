<template>
  <v-app-bar dark app color="primary">
    <div class="d-flex align-center">
      <v-img
        alt="IPTV"
        class="ml-4 logo"
        contain
        src="../assets/images/logo.png"
        transition="scale-transition"
      ></v-img>
    </div>
    <v-autocomplete
      solo
      light
      dense
      class="ml-2"
      v-model="selectedClientId"
      :items="clients"
      item-value="id"
      item-text="name"
      auto-select-first
      :cache-items="true"
      hide-details
      no-data-text="Nenhum provedor foi encontrado"
      @input="clientSelected"
      :disabled="clients.length === 1 || clientSelector.disabled"
      :filter="filterProviders"
      v-if="clientSelector.visible"
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="ml-2"
          @click="clientSelected"
          v-if="clientSelector.visible && !clientSelector.disabled"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>Atualizar</span>
    </v-tooltip>

    <v-spacer></v-spacer>

    <v-toolbar-items class="hidden-sm-and-down" v-if="menuItems.length">
      <v-btn
        v-for="item in menuItems"
        :key="item.label"
        text
        small
        :to="item.path"
        :auth="item.permission"
      >
        <span>{{ item.label }}</span>
      </v-btn>
    </v-toolbar-items>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" class="hidden-md-and-up">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.label"
          :to="item.path"
          :auth="item.permission"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          v-auth="[
            'PACKAGE.LIST',
            'PROVIDER.LIST',
            'SUBSCRIBER.EXPORT_EXCEL_REPORT',
            'SUBSCRIBER.EXPORT_JSON_FILE',
            'SYNC.REQUEST',
          ]"
        >
          <v-icon>mdi-cog-sync</v-icon>
        </v-btn>
      </template>

      <v-list>
        <!-- <v-list-item
          :to="'/pacotes'"
          v-auth="['PACKAGE.LIST']"
          v-if="hasPermission('PACKAGE.LIST')"
        >
          <v-list-item-icon>
            <v-icon>mdi-television-classic</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pacotes</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item
          :to="'/provedores'"
          v-auth="['PROVIDER.LIST']"
          v-if="hasPermission('PROVIDER.LIST')"
        >
          <v-list-item-icon>
            <v-icon>mdi-wan</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Provedores</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="exportFile('excel')"
          v-auth="['SUBSCRIBER.EXPORT_EXCEL_REPORT']"
          v-if="hasPermission('SUBSCRIBER.EXPORT_EXCEL_REPORT')"
        >
          <v-list-item-icon>
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Assinantes (Excel)</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="exportFile('csv')"
          v-auth="['SUBSCRIBER.EXPORT_EXCEL_REPORT']"
          v-if="hasPermission('SUBSCRIBER.EXPORT_EXCEL_REPORT')"
        >
          <v-list-item-icon>
            <v-icon>mdi-file-delimited</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Assinantes (CSV)</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="exportFile('json')"
          v-auth="['SUBSCRIBER.EXPORT_JSON_FILE']"
          v-if="hasPermission('SUBSCRIBER.EXPORT_JSON_FILE')"
        >
          <v-list-item-icon>
            <v-icon>mdi-code-json</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Assinantes (JSON)</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="'/sync'"
          v-auth="['SYNC.REQUEST']"
          v-if="hasPermission('SYNC.REQUEST')"
        >
          <v-list-item-icon>
            <v-icon>mdi-swap-vertical</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sincronização Nora</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-subheader>{{ currentUser.name }}</v-subheader>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item to="/perfis" v-auth="'PROFILE.ADD'">
          <v-list-item-icon>
            <v-icon>mdi-account-box-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Perfis</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/usuarios" v-auth="'USER.LIST'">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Usuários</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/admins" v-auth="'USER.LIST'">
          <v-list-item-icon>
            <v-icon>mdi-account-key</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Admins</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/alterar-senha">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Alterar senha</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <ProgressBar></ProgressBar>
  </v-app-bar>
</template>

<script>
import { eventHub } from "@/main";
import clientApi from "@/common/api/client.api";
import noraApi from "@/common/api/nora.api";
import userApi from "@/common/api/user.api";
import subscriberApi from "@/common/api/subscriber.api";
import authApi from "@/common/api/auth.api";
import providerTypes from "@/common/data/provider-types.data";
import ProgressBar from "@/components/layout/ProgressBar";

export default {
  name: "Menu",

  components: {
    ProgressBar,
  },

  data() {
    return {
      menuItems: [],
      providerTypes: providerTypes,
      clients: [],
      selectedClient: {},
      selectedClientId: null,
    };
  },
  computed: {
    clientSelector() {
      return this.$route.meta.clientSelector || {};
    },
    currentUser() {
      return userApi.getCurrentUser() || {};
    },
  },

  beforeMount() {
    eventHub.$on("REFRESH_CLIENTS", () => this.listClients());
  },

  created() {
    this.listClients();
    this.filterMenuItems();
  },

  beforeDestroy() {
    eventHub.$off("REFRESH_CLIENTS");
  },

  methods: {
    // workaround - ver como remover
    hasPermission(permission) {
      return authApi.hasPermission(permission);
    },

    filterProviders(item, text) {
      if (item.name) {
        const name = item.name.toLowerCase();
        const searchText = text.toLowerCase();

        return name.indexOf(searchText) > -1;
      }
    },

    filterMenuItems() {
      this.menuItems = [];

      if (this.hasPermission("SUBSCRIPTION_PLAN.LIST")) {
        this.menuItems.push({
          label: "Planos",
          permission: "SUBSCRIPTION_PLAN.LIST",
          path: "/planos-assinatura",
        });
      }

      if (this.selectedClient.type === "isp") {
        if (this.hasPermission("SUBSCRIBER.LIST")) {
          this.menuItems.push({
            label: "Assinantes",
            permission: "SUBSCRIBER.LIST",
            path: "/assinantes",
          });
        }

        if (this.hasPermission("STREAMING_DEVICE.LIST")) {
          this.menuItems.push({
            label: "Dispositivos",
            permission: "STREAMING_DEVICE.LIST",
            path: "/dispositivos",
          });
        }

        if (this.hasPermission("SUBSCRIPTION.LIST_CHANGES")) {
          this.menuItems.push({
            label: "Alterações assinaturas",
            permission: "SUBSCRIPTION.LIST_CHANGES",
            path: "/alteracoes-assinaturas",
          });
        }

        if (this.hasPermission("SUBSCRIPTION.LIST_CHANGES")) {
          this.menuItems.push({
            label: "Relatórios",
            permission: "SUBSCRIBER.LIST",
            path: "/reports/total-subscriptions",
          });
        }

        //  if (this.hasPermission("BILLING.LIST")) {
        //   this.menuItems.push({
        //     label: "Cobranças",
        //     permission: "BILLING.LIST",
        //     path: "/cobrancas",
        //   });
        // }
      }
    },

    listClients() {
      this.clients = [];
      clientApi.list({ size: -1 }).then((result) => {
        this.providerTypes.forEach((providerType) => {
          let filtered = result.data.data.filter(
            (provider) => provider.type === providerType.id
          );

          if (filtered.length) {
            this.clients.push({ header: providerType.name });
          }

          filtered.forEach((filteredProvider) => {
            this.clients.push({
              id: filteredProvider.id,
              name: filteredProvider.label,
              main: filteredProvider.main,
              type: filteredProvider.type,
            });
          });

          if (filtered.length) {
            this.clients.push({ divider: true });
          }
        });

        if (this.clients.length) {
          let lastSelectedClient = clientApi.getSelectedClient().id;
          let client;

          if (lastSelectedClient) {
            client = this.clients.find((c) => c.id === lastSelectedClient);
          }

          if (!client) {
            client = this.clients.find((c) => c.main);
          }

          if (!client) {
            // first item selection
            client = this.clients.find((c) => c.id);
          }

          this.selectedClientId = client.id;
          this.selectedClient = client;
        }

        this.clientSelected();
      });
    },

    clientSelected() {
      let client = this.clients.find((c) => c.id === this.selectedClientId);

      this.selectedClient = client;
      clientApi.setSelectedClient(client);
      eventHub.$emit("SELECTED_CLIENT", client);

      this.filterMenuItems();
    },

    async exportFile(type) {
      let message;
      let action;
      let providerId;
      if (type === "excel") {
        message = "subscriber.exportExcel";
        action = subscriberApi.exportExcel;

        if (userApi.getUserModule() !== "BACKOFFICE") {
          providerId = clientApi.getSelectedClient().id;
        }
      } else if (type === "csv") {
        message = "subscriber.exportCsv";
        action = subscriberApi.exportCsv;

        if (userApi.getUserModule() !== "BACKOFFICE") {
          providerId = clientApi.getSelectedClient().id;
        }
      }
      else {
        message = "subscriber.exportJson";
        action = subscriberApi.exportJson;
      }

      if (await this.$root.$confirm(true, message)) {
        action(providerId).then((response) => {
          let header = response.headers["content-disposition"];

          if (header) {
            let headerValue = header.split(";");

            if (headerValue.length === 2) {
              let filename = headerValue[1].replace(" filename=", "");

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            }
          }
        });
      }
    },

    async logout() {
      if (await this.$root.$confirm(true, "user.logout")) {
        userApi.logout();
        this.$router.replace("/login");
      }
    },

    async syncSubscriptionPlans() {
      if (await this.$root.$confirm(true, "nora.syncSubscriptionPlans")) {
        noraApi.syncSubscriptionPlans();
      }
    },

    async syncSubscribers() {
      if (await this.$root.$confirm(true, "nora.syncSubscribers")) {
        noraApi.syncSubscribers();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  width: 100px;
}
</style>
