<template>
  <v-app>
    <Menu></Menu>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Menu from "../components/Menu";

export default {
  name: "Backoffice",

  components: {
    Menu,
  },
};
</script>